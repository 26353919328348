<template>
  <svg viewBox="0 0 10 10">
    <path
      d="M5.00048 4.05781L8.30048 0.757812L9.24315 1.70048L5.94315 5.00048L9.24315 8.30048L8.30048 9.24315L5.00048 5.94315L1.70048 9.24315L0.757812 8.30048L4.05781 5.00048L0.757812 1.70048L1.70048 0.757812L5.00048 4.05781Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>
